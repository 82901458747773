import logo from '../logo.svg';
import '../App.css';
import { Link, useParams } from 'react-router-dom';
import ViewCarpool from './ViewCarpool'
import { useEffect, useState } from 'react';
import { checkPasswordProtection, validatePassword } from '../BackendCalls/Auth';
import UserDetails from './UserDetails';
import { getEvent } from '../BackendCalls/Event';
import { getEntries } from '../BackendCalls/Entries';
import { Button } from '@mui/material';
import {TextField} from '@mui/material';
import {Grid} from '@mui/material';
import '@fontsource/roboto/300.css';
import {Typography} from '@mui/material';
import Stack from '@mui/material/Stack';

import { getMatches } from '../BackendCalls/Matches';
import { createFlightEntry } from '../BackendCalls/FlightAPI';

function App(props) {
    const { AccessId } = useParams()
    const copyLink = () => {
        if (document.hasFocus()) {
            navigator.clipboard.writeText(window.location.href)
                .then(() => {
                    alert("Copied Link to Clipboard");
                })
                .catch((error) => {
                    console.error("Failed to copy link:", error);
                });
        } else {
            alert("Please focus on the document before copying the link.");
        }
    };
    

    const [isPasswordProtected, setIsPasswordProtected] = useState(true)
    const [AccessGrantedToUser, setAccessGrantedToUser] = useState(false)
    const [eventPassword, setEventPassword] = useState(null)
    const [pullEventData, setPullEventData] = useState(false)
    const [eventId, setEventId] = useState(null)
    const [showCarpools, setShowCarpools] = useState(false)
    const [entries, setEntries] = useState([])

    const [DestinationLat, setDestinationLat] = useState(0)
    const [DestinationLong, setDestinationLong] = useState(0)
    const [DestinationName, setDestinationName] = useState(null)

    const [loading, setLoading] = useState(true)
    const [showEntryView, setShowEntryView] = useState(true)
    const [username, setUsername] = useState("")
    const [details, setDetails] = useState("")

    const [eventName, setEventName] = useState("")

    const [isExistingUser, setIsExistingUser] = useState(false)


    useEffect(() => {
        async function setup(){
            
            console.log(AccessId)
            let protectedEvent = await checkPasswordProtection(AccessId)
            console.log(protectedEvent)
            let protectionDetails = protectedEvent.HasPassword
            setIsPasswordProtected(protectionDetails)
            if(!protectionDetails){
                setAccessGrantedToUser(true)
                setPullEventData(true)
            }else{
                setLoading(false)
            }
            console.log(protectionDetails)
            //createFlightEntry(200, '34034023', 'XHL2lqzUrJp1kcp61UxV9w',"aatishp@uw.edu",false,null,"LH500", "aatishp@uw.edu",32423,2342 )
        }
        setup()
    },[AccessId])

    // triggers if event is open to all or password passes
    useEffect(() => {
        async function getEventData(){
            console.log("getting event data")
            let eventData = await getEvent(AccessId, eventPassword)
            let eventId = eventData.data.EventId
            
            const dName = eventData.data.DestinationName
            const dLat = eventData.data.DestinationLatitude
            const dLong = eventData.data.DestinationLongitude
            
            setEventName(eventData.data.Name)
            console.log("event name: ", eventData.data.Name)
            setEventId(eventId)
            setDestinationLat(dLat)
            setDestinationLong(dLong)
            setDestinationName(dName)
            setPullEventData(false)
            await getEventDataFromDB(eventId)
            setLoading(false)
        }

        if(AccessGrantedToUser && pullEventData){
            getEventData()
            
            console.log("GETTING EVENT DATA")
        }
    },[AccessGrantedToUser, pullEventData])

    const getEventDataFromDB = async (id) => { // pass event id
        console.log("id",id)
        let data = await getEntries(id)
        console.log(data, "DATA SAVED TO ENTRIES")
        setEntries(data.data)
    }

    const passwordHandler = event => {
        const {value} = event.target
        setEventPassword(value)
        console.log(value)
    }

    const switchViewCallbackHandler = event => {
        props.switchViewCallback(!showEntryView)
    }

    const validatePasswordAttempt = async () => {
        let res = await validatePassword(AccessId, eventPassword)
        console.log(res)
        let accessGiven = res.AccessGranted
        if(accessGiven){ // user got password right
            setAccessGrantedToUser(accessGiven)
            setPullEventData(true)
        }else{
            setAccessGrantedToUser(accessGiven)
            alert("Sorry, that was incorrect please try again")
        }
    }

    const displayCarpools = async (display) => {
        console.log("Display carpools")
        await getEventDataFromDB(eventId)
        setShowCarpools(display)
    }

    const switchView = () => {
        setShowEntryView(!showEntryView)
    }

    const markUserExists = () => {
        setIsExistingUser(true)
    }

    const passUsername = (name) => {
        console.log("usernamecalled parent", name)
        setUsername(name)
    }

    const passDetails = (details) => {
        console.log("detail parent", details)
        setDetails(details)
    }

    if(loading){
        return(<div>Loading</div>)
    }
    if(AccessGrantedToUser == false){
        return(
            
            <div className='App'>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <Grid item xs={3}>
                        <TextField
                            id="outlined-password-input"
                            label="Event Password"
                            type="password"
                            autoComplete="current-password"
                            variant="outlined"
                            size="small"
                            onChange={passwordHandler}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" href="#outlined-buttons" 
                            onClick={validatePasswordAttempt}>Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    }else if(showEntryView){
        
        //collect user details
        return (
            <div className='App'>
                <br />
                <Stack spacing={1} width={200} justifyContent="center" alignItems="center" alignContent="center">
                    {/* <Button
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={switchView}
                        >View Pooling Map
                    </Button> */}
                    {/* <Button
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={copyLink}
                        >Copy Event Link
                    </Button> */}
                    
                </Stack>
                <UserDetails eventId = {eventId}  carpoolData = {entries} usernameCallback = {passUsername} usernameParent = {username} detailsCallback = {passDetails} detailsParent = {details} showCarpoolsCallback = {displayCarpools} switchViewCallback = {switchView} userDoesExistCallback = {markUserExists} userExists = {isExistingUser}/>
                
                </div>
        );
        
    }else{
        return(
            <div className='App'>

                <img style={{padding: "25px 0px 25px 0px"}} width="300" src="https://github.com/TechBash/Marketing/blob/master/2023/Logos/TechBash2023.png?raw=true"/>
                <Stack direction="row" spacing={1} justifyContent="center">
                    <Button
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={copyLink}
                        >Copy Event Link
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={switchView}
                        >Edit Details
                    </Button>
                </Stack>
                <ViewCarpool carpoolData = {entries} username={username} details = {details} accessId = { AccessId } eventId = {eventId} lat = {DestinationLat} long = {DestinationLong} name = {DestinationName} switchViewCallback = {switchView}/>
            </div>
           
        )
    }
  
}

export default App;
