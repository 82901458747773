const baseUrl = "https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/"
const API_KEY = "dBXTyoL0d22tJjXPabIYX7OaMmiluIvbRZGMgUVb"


//EventID is actually ACCESS ID
export const getMatches = async(EventId, AccessId, EntryId, PlotDate) => {
    let data = null
    await fetch(baseUrl + "matches?EventId=" + EventId + "&EntryId=" + EntryId + "&PlotDate=" + PlotDate + "&AccessId=" + AccessId, {
        method : "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
        
    }).then(async res => {
        data = await res.json()
        // console.log(data)
    })
    return data
}