import logo from './logo.svg';
import './App.css';
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { createEvent, getEvent } from './BackendCalls/Event';
import { getEntries, createEntry } from './BackendCalls/Entries';
import { checkPasswordProtection, validatePassword } from './BackendCalls/Auth';
import { Routes, Route } from 'react-router-dom';

import CreateEvent from './Components/CreateEvent'
import CreateEntry from './Components/CreateEntry'
import ViewCarpools from './Components/ViewCarpool'
import UserDetails from './Components/UserDetails';
import ChooseTransport from './Components/ChooseTransport';

function App() {
  return (
    <Routes>
      <Route path='/' element={<CreateEvent/>}></Route>
      <Route path='entry/:AccessId' element={<CreateEntry/>}></Route>
      <Route path='carpools' element={<ViewCarpools/>}></Route>
    </Routes>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //     <button onClick={() => createEvent("SPECIAL NAME", false, "oiasjf")}>Create Event</button>
    //     <button onClick={() => getEvent("7ced52e6-ee29-48b0-b804-3523fd943f6a")}>Get Event</button>
    //     <button onClick={() => getEntries("ZdXGtIZuAnD97W2meBdvxA")}>Get Entries</button>
    //     <button onClick={() => createEntry(5,"details","u40XZrsJkxunQjoLB3eyDw",43.555,443.343,"joel",false,"pepsi")}>Create Entry</button>
    //     <button onClick={() => checkPasswordProtection("648555d3-825f-4fd6-9fdb-99e42230f847")}>Check Password Protection</button>
    //     <button onClick={() => validatePassword("f7efe83e-8331-467b-9ef1-0606d4af0b47","Password")}>Check Password is correct</button>
    //   </header>
    // </div>
  );
}

export default App;
