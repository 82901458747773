import logo from '../logo.svg';
import '../App.css';
import React, { useEffect, useState } from 'react';
import GoogleMapReact, {Map, GoogleApiWrapper} from 'google-map-react'
import { Marker } from './MapMarker';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getMatches } from '../BackendCalls/Matches';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function ViewCarpool(props) {
    const { carpoolData } = props
    const { lat } = props
    const { long } = props
    const { name } = props
    const { username } = props
    const { eventId } = props
    const { accessId } = props

    // // console.log(lat, long, name, carpoolData, "PROPS for VIEW CARPOOL")
    const [formatMapData, setFormatMapData] = useState([])
    const [mapLoaded, setMapLoaded] = useState(false)
    const [matches, setMatches] = useState([])

    

    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 12
      };

      const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        // console.log(carpoolData)
        // console.log("LOADED", carpoolData.length)
        setFormatMapData(carpoolData)
        
        const destinationMarker = new maps.Marker({
          position : { lat: parseFloat(lat), lng: parseFloat(long) },
          map,
          draggable :false,
          title : "DESTINATION: " + name
        })
        new maps.Circle({
          strokeColor: "#00BF00",
          strokeOpacity: 0.8,
          strokeWeight: 5,
          fillColor: "#00BF00",
          fillOpacity: 0.5,
          map,
          center:  { lat: parseFloat(lat), lng: parseFloat(long) },
          radius: 200,
          draggable: false,
        });
        

        var infowindow = new maps.InfoWindow({
          content: "Destination: " + name
        });

        maps.event.addListener(destinationMarker, 'click', function() {
          infowindow.open(map,destinationMarker);
        });
        // console.log(username, "USERNAME")
        try{
          formatMapData.map(entry => {
            if(entry.Username === username){
              // console.log("MATCHING USERNAME" , entry)
              new maps.Circle({
                strokeColor: "#1b7ced",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#1b7ced",
                fillOpacity: 0.35,
                map,
                center: { lat: parseFloat(entry.Latitude), lng: parseFloat(entry.Longitude) },
                radius: parseInt(entry.Radius),
                draggable: false,
              });
    
              const marker = new maps.Marker({
                position : { lat: parseFloat(entry.Latitude), lng: parseFloat(entry.Longitude) },
                map,
                draggable :false,
                title : "YOU"
              })
              let detailsToShow = "contact them for more information"
              if(entry.Details !== null){
                detailsToShow = entry.Details
              }
              var infowindow = new maps.InfoWindow({
                content: entry.Username + ", " + detailsToShow
              });
              maps.event.addListener(marker, 'click', function() {
                infowindow.open(map,marker);
              });
            }else{
              // console.log("NON MATCHING USER NAME", entry)
              new maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map,
                center: { lat: parseFloat(entry.Latitude), lng: parseFloat(entry.Longitude) },
                radius: parseInt(entry.Radius),
                draggable: false,
              });
    
              const marker = new maps.Marker({
                position : { lat: parseFloat(entry.Latitude), lng: parseFloat(entry.Longitude) },
                map,
                draggable :false,
                title : entry.Username
              })
              let detailsToShow = "contact them for more information"
              if(entry.Details !== null){
                detailsToShow = entry.Details
              }
              var infowindow = new maps.InfoWindow({
                content: entry.Username + ", " + detailsToShow
              });
              maps.event.addListener(marker, 'click', function() {
                infowindow.open(map,marker);
              });
            }
            
          })
        }
        catch(error){
          // console.log(error)
        }
        setMapLoaded(true)
      };

      useEffect(() => {
        // console.log("flipped map loaded to false")
        setMapLoaded(false)
        setFormatMapData(carpoolData)
        // console.log("LOADING CARPOOL DATA")
        
      }, [carpoolData])

      useEffect(() => {
        setMapLoaded(true)
        // console.log("Flipped map loaded to true")
      }, [formatMapData])

      useEffect(() => {
        async function pullingMatches(){
          // console.log("pulling matches")
          const res = await getMatches(eventId, accessId, null, null)
          // convert to arrays so can iterate, ned to fix
          // console.log(res, "MATCHES FOUND")
          const TEMP_USERNAME = username
          const matches = Object.values(JSON.parse(res.data))
          // console.log(matches, "just matches", typeof matches[0], typeof matches, typeof matches[0][0])
          for(let pool in matches){
            // console.log(matches[pool], "Going over pools")
            for(let entry in matches[pool]){
              // console.log(matches[pool])
              // console.log(matches[pool][entry].Username, "going over entries per pool")
              if(matches[pool][entry].Username == TEMP_USERNAME){
                // console.log("Found username in pools", matches[pool])
                setMatches(Object.values(matches[pool]))
                break
              }
            }
          }
        }
        pullingMatches() 
      },[])

      const refreshMapMarkers = () => {
        // console.log("REFRESHING MAP", mapLoaded)
        //setMapLoaded(!mapLoaded)
        
      }

      const convertEpoch = (dateStart, dateEnd) => {
        let start = new Date(dateStart)
        let end = new Date(dateEnd)
        console.log("start ", start)
        console.log("end ", end)
        return (start.toLocaleDateString() + " " + start.toLocaleTimeString() + " - " + end.toLocaleDateString() + " " + end.toLocaleTimeString())
      }

  return (
    <div style={{ height: window.innerHeight * 0.8, width: '100%' }}>
      <div>
        <h2>Your Matches</h2>
        <React.Fragment>
        <Table size="small">
          <TableHead style={{width: "100"}}>
          {(matches.toString() == "") ?
              <p>No matches found. Check the map to manually find potential carpools on route.</p> :
              <div>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Pickup Window</TableCell>
                </TableRow>
                <TableBody>
                  {matches.map((entry) => (
                      <TableRow key={entry.Username}>
                        <TableCell>{entry.Email}</TableCell>
                        <TableCell>{entry.Details}</TableCell>
                        <TableCell>{convertEpoch(entry.StartWindow, entry.EndWindow)}</TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </div>
          }
          </TableHead>
        </Table>
      </React.Fragment>
      </div>
      <p>If no markers are showing please reload the page</p>
        {mapLoaded ? 
            <GoogleMapReact
            
            bootstrapURLKeys={{ key: "AIzaSyDf91rCcN8h1MocYG588mue1B-_Fy5BHJo" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            center={{lat : parseFloat(lat), lng : parseFloat(long)}}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            onDragEnd={() => refreshMapMarkers()}
            draggable={true}
            >
            
            </GoogleMapReact>
            :
            <p>Loading Map</p>
          
        }
        <p>Click on markers for details</p>
    </div>
  );
}

export default ViewCarpool;
