import logo from '../logo.svg';
import '../App.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { createEvent } from '../BackendCalls/Event'
import { getAddressGeocode } from '../BackendCalls/LocationServices';

import GoogleMapReact from 'google-map-react'


const AnyReactComponent = ({ text }) => <div style={{color:'red'}}>{text}</div>;

function CreateEvent() {
    const [eventName, setEventName] = useState(null)
    const [hasPassword, setHasPassword] = useState(false)
    const [password, setPassword] = useState(null)
    const [createdEvent, setCreatedEvent] = useState(false)
    const [AccessId, setAccessId] = useState(null)
    const [DestinationLat, setDestinationLat] = useState(0)
    const [DestinationLong, setDestinationLong] = useState(0)
    const [DestinationName, setDestinationName] = useState(null)
    const [DestinationAddr, setDestinationAddr] = useState(null)
    const [addrFound, setAddrFound] = useState("Geocoded Location")
    const [showPin, setShowPin] = useState(false)
    const [showMap, setShowMap] = useState(false)

    const navigate = useNavigate()

    const nameHandler = event => {
        const {value} = event.target
        setEventName(value)
        console.log(value)
    }

    const dNameHandler = event => {
        const {value} = event.target
        setDestinationName(value)
        console.log(value)
    }

    const dAddressHandler = event => {
        const {value} = event.target
        setDestinationAddr(value)
        console.log(value)
    }

    const dLatHandler = event => {
        const {value} = event.target
        setDestinationLat(value)
        console.log(value)
    }
    const dLongHandler = event => {
        const {value} = event.target
        setDestinationLong(value)
        console.log(value)
    }

    const hasPasswordHandler = event => {
        setHasPassword(!hasPassword)
        
    }

    const passwordHandler = event => {
        const {value} = event.target
        setPassword(value)
        console.log(value)
    }

    const createEventCall = async () => {
        if(eventName == null || eventName == ""){
            alert("Please enter an event name")
        }
        else if(hasPassword && (password == null || password == "") ){
            alert("Please enter a password")
        }else if(DestinationAddr === null || DestinationAddr === "" || DestinationLat == null || DestinationLong == null){
            alert("Please enter an address that is shown on map (you might have forgot to press enter on address)")
        
        }else{
            let res = await createEvent(eventName, hasPassword, password, DestinationAddr, DestinationLat, DestinationLong)
            console.log(res)
            let accessId = res.data.AccessId
            setAccessId(accessId)
            setCreatedEvent(true)
            alert("Created your event!")
        }
    }

    const searchAddress = async () => {
        setShowMap(false)
        let res = await getAddressGeocode(DestinationAddr)
        console.log(res)
        if(res != null){
            setDestinationLat(res.lat)
            setDestinationLong(res.lon)
            setAddrFound(res.addrFound)
            setShowMap(true)
        }else{
            alert("Address not found, please try again")
        }
        
    }

    // const copyLink = () => {
    //     navigator.clipboard.writeText(window.location.href +  'entry/' + AccessId)
    //     alert("Copied Link to Clipboard")
    // }

    // new code to resolve "document is not focused" error
    const copyLink = () => {
        if (document.hasFocus()) {
            const link = window.location.href + 'entry/' + AccessId;
            navigator.clipboard.writeText(link)
                .then(() => {
                    alert("Copied Link to Clipboard");
                })
                .catch((error) => {
                    console.error('Failed to copy link:', error);
                });
        } else {
            alert("Please focus on the document before copying the link.");
        }
    };
    

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        if(DestinationAddr != "" && DestinationAddr != null){
            let marker = new maps.Marker({
                position: {lat : parseFloat(DestinationLat), lng : parseFloat(DestinationLong)},
                map,
                draggable:false,
              });
            setShowPin(true)
        }
        
      };

  return (
    <div className="App">
     
        {showMap && DestinationLat != undefined && DestinationLong != undefined ? 
        <div style={{ height: window.innerHeight * 0.5, width: '100%' }}>
            
            <GoogleMapReact
            
                bootstrapURLKeys={{ key: "AIzaSyDf91rCcN8h1MocYG588mue1B-_Fy5BHJo" }}
                defaultZoom={17}
                center={{lat : parseFloat(DestinationLat), lng : parseFloat(DestinationLong)}}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                draggable={false}
                >
                {/* {showPin ? 
                <AnyReactComponent
                    lat={parseFloat(DestinationLat)}
                    lng={parseFloat(DestinationLong)}
                    text={addrFound}
                />
                : null} */}
            </GoogleMapReact>
        </div>
        :<h1>Conference Pool</h1>}
        <p>Enter a name for the carpooling occasion:</p>
        <input placeholder='Event Name' onChange={nameHandler} style={{fontSize: "18px", border: "none", borderBottom: "2px solid lightgrey", marginBottom: "5px"}}/>
        <p>Enter the final destination for the event:</p>
        <input placeholder='Destination Address' onChange={dAddressHandler} style={{fontSize: "18px", border: "none", borderBottom: "2px solid lightgrey"}} onBlur={searchAddress}  onKeyDown={event => {
            if(event.key === 'Enter'){
                searchAddress()
            }
        }}/>
        <p style={{fontSize: "16px", fontWeight: "lighter"}}>Press enter after typing/searching address to view map and verify address</p>
        {/* <input placeholder='Destination Latitude' onChange={dLatHandler} value={DestinationLat}/>
        <input placeholder='Destination Longitude' onChange={dLongHandler} value={DestinationLong}/> */}
        <p style={{fontSize: "16px", fontWeight: "lighter"}}>Enable Password Protection?</p>
        <input type="checkbox" size={200} checked={hasPassword} onChange={hasPasswordHandler}/>
        {hasPassword ? 
        <div>
            <p>Password Protected</p>
            <input style={{fontSize: "18px", border: "none", borderBottom: "2px solid lightgrey"}} placeholder='Event Password' onChange={passwordHandler}/>
        </div>
        
            :
        <p style={{fontSize: "16px", fontWeight: "lighter"}}>Not Password Protected</p>
        }

        {createdEvent ?
            <div>
                <button style={{display: "inline-block", borderRadius: "500px", lineHeight: "1", fontSize: "14px", height: "38px"}} onClick={() => navigate('entry/' + AccessId)}>Enter your Carpool Details</button>
                <br />
                <button style={{marginTop: "5px", display: "inline-block", borderRadius: "500px", lineHeight: "1", fontSize: "14px", height: "38px"}} onClick={copyLink}>
                    Copy Your Event Link
                </button>
            </div>
        :
            <button onClick={() => createEventCall()}>Create Event</button>
        }
        
    </div>
  );
}

export default CreateEvent;
