const baseUrl = "https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/"
const API_KEY = "dBXTyoL0d22tJjXPabIYX7OaMmiluIvbRZGMgUVb"




export const checkPasswordProtection = async(AccessId) => {
    let data = null
    await fetch("https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/checkprotection" + "?AccessId=" + AccessId,{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        data = await res.json()
        
    })
    return data
}

export const validatePassword = async(AccessId, Password) => {
    let data = null
    await fetch("https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/eventauth" + "?AccessId=" + AccessId + "&Password=" + Password,{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        data = await res.json()
        
    })
    return data
}


export const validateUserPassword = async(EventId, Username, Password) => {
    let data = null
    await fetch(" https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/userauth" + "?EventId=" + EventId + "&Username=" + Username + "&Password=" + Password,{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        data = await res.json()
        
    })
    return data
}