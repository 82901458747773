const API_KEY = "dBXTyoL0d22tJjXPabIYX7OaMmiluIvbRZGMgUVb"

export const getAddressGeocode = async(Address) => {
    let resData = null
    await fetch("https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/searchaddress?Address=" + encodeURIComponent(Address),{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        let response = await res.json()
        // console.log(response)
        resData = response
    })
    if("geocodeResult" in resData){
        return {lat : resData.geocodeResult.lat, lon : resData.geocodeResult.lon, addrFound : resData.geocodeResult.display_name}
    }
    return null
}