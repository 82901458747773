const baseUrl = "https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/"
const API_KEY = "dBXTyoL0d22tJjXPabIYX7OaMmiluIvbRZGMgUVb"

export const createEvent = async(EventName, HasPassword, Password, DestinationName, DestinationLat, DestinationLong) => {
    let resData = null
    await fetch(baseUrl + "events",{
        method:"POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            },
        body : JSON.stringify({
            Name : EventName,
            HasPassword : HasPassword,
            Password : Password,
            DestinationName : DestinationName,
            DestinationLatitude : DestinationLat,
            DestinationLongitude : DestinationLong

        })
    }).then(async res => {
        const data = await res.json()
        // console.log(data, "CREATE EVENT DATA")
        resData = data
    })
    return resData
}

export const getEvent = async(AccessId, Password) => {
    let resData = null
    await fetch(baseUrl + "events?AccessId=" + AccessId + "&Password=" + encodeURIComponent(Password),{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        let response = await res.json()
        // console.log(response)
        resData = response
    })
    return resData
}
