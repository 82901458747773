import logo from '../logo.svg';
import '../App.css';
import * as React from 'react';
import {TextField, Grid} from '@mui/material';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import { DatePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import { useState, useEffect } from 'react';
import { createEntry, updateEntry } from '../BackendCalls/Entries';
import { validateUserPassword } from '../BackendCalls/Auth';
import { getAddressGeocode } from '../BackendCalls/LocationServices';
import GoogleMapReact from 'google-map-react'
import { ToggleButtonGroup } from '@mui/material';
import {ToggleButton} from '@mui/material';
import { createFlightEntry } from '../BackendCalls/FlightAPI';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
// import { test_flight } from '../BackendCalls/FlightAPI';
//import { getLandingTime } from '../BackendCalls/FlightAPI';

const AnyReactComponent = ({ text }) => <div style={{color:'red'}}>MOVE ME (Not too far though)</div>;

function UserDetails(props) {
    const [alignment, setAlignment] = useState('flight');

    const locales = ['en', 'en-gb', 'zh-cn', 'de'];
    const [locale, setLocale] = React.useState('en');

    const { eventId } = props
    const { carpoolData } = props
    const { userExists } = props
    const { usernameParent } = props
    const { detailsParent } = props


    const [isAnExistingUser, setIsExistingUser] = useState(false)
    const [signedIn, setSignedIn] = useState(false)
    const [signedUp, setSignedUp] = useState(false)

    const [hasPassword, setHasPassword] = useState(false)
    const [password, setPassword] = useState(null)
    const [flightNumber, setFlightNumber] = useState(null)

    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)
    const [affiliation, setAffiliation] = useState(null)
    const [radius, setRadius] = useState(200)
    const [latitudeStart, setLatitudeStart] = useState(0)
    const [longitudeStart, setLongitudeStart] = useState(0)
    const [latitudeEnd, setLatitudeEnd] = useState(0)
    const [longitudeEnd, setLongitudeEnd] = useState(0)
    const [details, setDetails] = useState(null)
    const [plotDate, setPlotDate] = useState(null)

    const [startTime, setStartTime] = useState(new Date().getTime())
    const [endTime, setEndTime] = useState(new Date().getTime() + 3600000)


    const [showMap, setShowMap] = useState(false)
    const [showPin, setShowPin] = useState(false)
    const [addrIn, setaddrIn] = useState("Your Address...")
    const [addrFound, setAddrFound] = useState('')

    useEffect(() => {
        setIsExistingUser(userExists)
        if(userExists){
            setSignedIn(true)
            setSignedUp(true)
            // populate fields 
            
        }
        if(usernameParent != null){
            setUsername(usernameParent)
        }
        if(details != null){
            setDetails(detailsParent)
        }
    }, [userExists, usernameParent, detailsParent])

    const copyLink = () => {
        if (document.hasFocus()) {
            navigator.clipboard.writeText(window.location.href)
                .then(() => {
                    alert("Copied Link to Clipboard");
                })
                .catch((error) => {
                    console.error("Failed to copy link:", error);
                });
        } else {
            alert("Please focus on the document before copying the link.");
        }
    };
    
    const hasPasswordHandler = event => {
        setHasPassword(!hasPassword)
        
    }

    const passwordHandler = event => {
        const {value} = event.target
        setPassword(value)
        // console.log(value)
    }

    const signUpHandler = event => {
        setSignedUp(true)
        // console.log(signedUp)
        // getLandingTime('AS588', '2023-12-14')
    }

    const emailHandler = event => {
        const {value} = event.target
        // console.log("SETTING EMAIL AND USERNAME: " + value)
        setEmail(value.toLowerCase())
        setUsername(value.toLowerCase())
    }

    const detailsHandler = event => {
        const {value} = event.target
        setDetails(value)
        props.detailsCallback(value)
    }

    const affiliationHandler = event => {
        const {value} = event.target
        setAffiliation(value)
    }

    const usernameHandler = event => {
        const {value} = event.target
        setEmail(value)
        setUsername(value)
    }


    const radiusHandler = event => {
        const {value} = event.target
        setRadius(value)
        
    }

    const switchViewHandler = event => {
        props.switchViewCallback()
    }

    const latitudeStartHandler = event => {
        const {value} = event.target
        setLatitudeStart(value)
    }

    const longitudeStartHandler = event => {
        const {value} = event.target
        setLongitudeStart(value)
    }

    const latitudeEndHandler = event => {
        const {value} = event.target
        setLatitudeEnd(value)
    }

    const longitudeEndHandler = event => {
        const {value} = event.target
        setLongitudeEnd(value)
    }


    const addrHandler = event => {
        const {value} = event.target
        setaddrIn(value)
    }

    const handleFlightNumber = (event) => {
        const {value} = event.target
        setFlightNumber(value)
      }

    const startTimeHandler = event => {
        // console.log(new Date(event).getTime())
        setStartTime(new Date(event).getTime())
    }
    
    const endTimeHandler = event => {
        // console.log(event)
        setEndTime(new Date(event).getTime())
    }

    const searchAddress = async (addr) => {
        setShowMap(false)
        let res = await getAddressGeocode(addr)
        // console.log(res)
        if(res != null){
            setLatitudeStart(res.lat)
            setLongitudeStart(res.lon)
            setAddrFound(res.addrFound)
            setShowMap(true)
        }else{
            alert("Address not found, please try again")
        }
        
    }

    const createEntryUX = async () => {
        // add latitudeEnd and longitudeEnd later
        
        if(radius == null || latitudeStart === 0 || longitudeStart === 0 || details == null || username == null){
            // console.log(radius, latitudeStart, longitudeStart, details, username)
            alert("Please make sure all fields are filled")
        }else{
            // console.log(email, radius, details, eventId, latitudeStart, longitudeStart, latitudeEnd, longitudeEnd, username, startTime, endTime, hasPassword, password)
            props.usernameCallback(username)
            props.detailsCallback(details)
            // console.log("username in callback", username)
            if(!isAnExistingUser){
                const res = await createEntry(radius, details, eventId, latitudeStart, longitudeStart, username, hasPassword, password, false, startTime, endTime, null,email, 41.105510, -75.382340)
            }else{
                const res = await updateEntry(eventId, plotDate, radius, details, eventId, latitudeStart, longitudeStart, username, hasPassword, password, false, startTime, endTime, 2,null,email, 41.105510, -75.382340)
            }
            
            props.showCarpoolsCallback(true)
            props.switchViewCallback()
        }
        
    }

    const createFlightEntryUX = async () => {
        // add latitudeEnd and longitudeEnd later
        
        if(flightNumber == null){
            // console.log(radius, details, eventId, username, hasPassword, password, flightNumber, email, latitudeEnd, longitudeEnd)
            alert("Please make sure all fields are filled")
        }else{
            // console.log(radius, details, eventId, username, hasPassword, password, flightNumber, email, latitudeEnd, longitudeEnd)
            props.usernameCallback(username)
            props.detailsCallback(details)
            // console.log("username in callback", username)
            if(!isAnExistingUser){
                // console.log("COOKING FLIGHTS")
                // console.log(radius, details, eventId, email, hasPassword, password, flightNumber, email, latitudeEnd, longitudeEnd)
                const res = await createFlightEntry(radius, details, eventId, username, hasPassword, password, flightNumber, email, latitudeEnd, longitudeEnd)
            }
            // else{
            //     const res = await updateEntry(eventId, plotDate, radius, details, eventId, latitudeStart, longitudeStart, username, hasPassword, password, false, startTime, endTime, 2,null,email,0,0)
            // }
            
            
            props.showCarpoolsCallback(true)
            props.switchViewCallback()
        }
        
    }

    useEffect(() => {
        // console.log("CHECKING IF ALREADY EXISTS")
        if(checkForUserExisting(username)){
            // console.log("EXISTING USER")
            setIsExistingUser(true)
            // console.log(carpoolData)
            carpoolData.forEach(element => {
                // console.log("Looking for element")
                if(element.Username === username){
                    // console.log("FOUND ELEMENT: " + JSON.stringify(element))
                    setEmail(element.Email)
                    setRadius(element.Radius)
                    setLatitudeStart(element.Latitude)
                    setLongitudeStart(element.Longitude)
                    setLatitudeEnd(element.LatitudeEndLocation)
                    setLongitudeEnd(element.LongitudeEndLocation)
                    setDetails(element.Details)
                    setStartTime(element.StartWindow)
                    setEndTime(element.EndWindow)
                    setHasPassword(element.HasPassword)
                    setPassword(null) // TEMP
                    setPlotDate(element.PlotDate)
                    setFlightNumber(element.FlightNumber)
                }
            });
        }else{
            // console.log("NOT EXISTING USER")
            setIsExistingUser(false)
        }
    }, [username])

    

    const checkForUserExisting = (username) => {
        // console.log(username, "USERNAME IN")
        for(let i = 0; i < carpoolData.length; i++){
            // console.log(carpoolData[i].Username)
            if(carpoolData[i].Username == username){
                // console.log("match")
                return true
            }
        }
        return false
    }
    const getExistingUserData = username => {
        for(let i = 0; i < carpoolData.length; i++){
            if(carpoolData[i].Username == username){
                return carpoolData[i]
            }
        }
    }

    const signInUser = () => {
        // console.log(username, checkForUserExisting(username))
        
        if(checkForUserExisting(username) == false){
            alert("User not found, please create an account!")
        }
        else{
            createUser()
        }
    }

    const signUpUser = () => {
        // console.log("SIGNING UP USER PREV VALUE: " + username)
        setUsername(null)
        createUser()
    }

    const createUser = async () => {
        if(checkForUserExisting(username) == false){
            // console.log("User doesn't exist")
            setSignedIn(true)
        }else{
            // console.log("user exists")
            let userData = getExistingUserData(username)
            if(userData.HasPassword){
                const correctPassword = (await validateUserPassword(eventId, username, password)).AccessGranted
                if(correctPassword){
                    setRadius(userData.Radius)
                    setLatitudeStart(userData.Latitude)
                    setLongitudeStart(userData.Longitude)
                    setLatitudeEnd(userData.LatitudeEndLocation)
                    setLatitudeEnd(userData.LongitudeEndLocation)
                    setDetails(userData.Details)
                    setPlotDate(userData.PlotDate)
                    setSignedIn(true)
                }else{
                    alert("Incorrect password")
                }

            }else{
                // confirm that this user exists and wants to modify the data
                setRadius(userData.Radius)
                setLatitudeStart(userData.Latitude)
                setLongitudeStart(userData.Longitude)
                setLatitudeEnd(userData.LatitudeEndLocation)
                setLongitudeEnd(userData.LongitudeEndLocation)
                setDetails(userData.Details)
                setSignedIn(true)
                setPlotDate(userData.PlotDate)
            }
            props.switchViewCallback()
            props.usernameCallback(username)
            props.detailsCallback(details)
            props.showCarpoolsCallback(true)
            props.userDoesExistCallback()
        }
    }

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    if(addrIn !== "" && addrIn !== null){
        // console.log("MAKING DRAGGABLE OBJS")
        const cityCircle = new maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map,
            center: { lat: parseFloat(latitudeStart), lng: parseFloat(longitudeStart) },
            radius: parseInt(radius),
            draggable: true,
          });
      
      
       let marker = new maps.Marker({
              position: { lat: parseFloat(latitudeStart), lng: parseFloat(longitudeStart)  },
              map,
              draggable:false,
            });
        setShowPin(true)

        marker.addListener("dragend", async () => {
            let coords = await marker.getPosition()
            
            let lat = await coords.lat()
            let lng = await coords.lng()
            // console.log(coords, lat, lng)
            setLatitudeStart(lat)
            setLongitudeStart(lng)
        })

        cityCircle.addListener("dragend", async () => {
            let coords = await cityCircle.getCenter()
            
            let lat = await coords.lat()
            let lng = await coords.lng()
            // console.log(coords, lat, lng)
            setLatitudeStart(lat)
            setLongitudeStart(lng)
        })
    }
    
    
  };

  const handleChildClick = (map, maps) => [
    // console.log("CHILD CLICKED")
  ]


    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);
    const autocompleteService = { current: null };
    const [value2, setValue2] = React.useState(null);
    const [inputValue2, setInputValue2] = React.useState('');
    const [options2, setOptions2] = React.useState([]);
    const loaded2 = React.useRef(false);
    const autocompleteService2 = { current: null };
    const googleApiKey = "AIzaSyD2szXNTsv0SqviZ4mSTN82gPYbA0eRChs"

    if (typeof window !== 'undefined' && !loaded.current) {
        // console.log("load script");
        if (!document.querySelector('#google-maps')) {
            // console.log("load place script")
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`,
            document.querySelector('head'),
            'google-maps',
          );
        }
    
        loaded.current = true;
      }

    // await this fetch
    const fetch = React.useMemo(
        () =>
          debounce((request, callback) => {
            // console.log("request")
            // console.log(request)
            // console.log("here", request.input)
            // console.log(callback)
            if (autocompleteService.current != null) {
                // console.log(autocompleteService.current.getPlacePredictions(request, callback))
                autocompleteService.current.getPlacePredictions(request, callback);
            }
          }, 400),
        [],
      );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
        const sleep = ms => new Promise(r => setTimeout(r, ms));
        sleep(1000)
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("pending")
        console.log("await the fetch")
        // console.log("!autocompleteService.current && window.google")
        // console.log("pending")
        // console.log(autocompleteService.current)
        // console.log("pending")
        autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    // console.log("value" + value);
    // console.log("inputValue" + inputValue);
    // console.log("fetch" + fetch)

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);


  if(signedIn == false){
    return(
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <img style={{padding: "25px 0px 20px 0px"}} width="300" src="https://github.com/TechBash/Marketing/blob/master/2023/Logos/TechBash2023.png?raw=true"/>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={emailHandler}
                autoFocus
                />
                
                {/* <FormControlLabel
                control={<Checkbox value="off" color="primary" />}
                label="Enable Password Protection"
                onChange={hasPasswordHandler}
                /> */}
                {/* {hasPassword ? 
                    <div>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={passwordHandler}
                        />
                    </div>
                    :
                    null
                } */}
                <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={signInUser}
                >
                Sign In
                </Button>
                <Grid container>
                {/* <Grid item xs>
                    <Link href="#" variant="body2">
                    Forgot password?
                    </Link>
                </Grid> */}
                <Grid item>
                    <Link onClick={signUpUser} variant="body2" component="button">
                    {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
                </Grid>
            </Box>
            </Box>
        </Container>
    )
  }

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }
  
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }

  

  return (
    <div className="App">
        
        {signedUp == true ? //flight or rideshare
        
            <Stack alignItems="center" spacing={3}>
                <img style={{padding: "25px 0px 20px 0px"}} width="300" src="https://github.com/TechBash/Marketing/blob/master/2023/Logos/TechBash2023.png?raw=true"/>
                <Button
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={copyLink}
                >Copy Event Link
            </Button>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    {/* <ToggleButton value="flight" aria-label="flight">Flight</ToggleButton> */}
                    <ToggleButton value="rideshare" aria-label="rideshare">Rideshare</ToggleButton>
                </ToggleButtonGroup>
                {/* {alignment == "flight" ? 
                <div>
                    <Stack spacing={2} width={200} alignItems="center" justifyContent="center">
                        <TextField id="flight-number" 
                            label="Flight #" 
                            variant="outlined" 
                            size="small"
                            onChange={handleFlightNumber}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                            <DatePicker
                                label="Departure Date"
                                // <MobileDateTimePicker onChange={value => startTimeHandler(value)} label="Earliest Departure Time" size="small" value ={dayjs(new Date(startTime))} />
                                defaultValue={dayjs('2023-11-17')}  // dayjs('2023-09-07')
                                onChange={(newValue) => setValue(newValue)}
                            />
                        </LocalizationProvider> }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={createFlightEntryUX}
                        >
                        Confirm
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            onClick={switchViewHandler}
                            >Cancel
                        </Button>
                    </Stack>
                    
                </div>
                : */}
                <div>
                    <Stack spacing={3} width={200} alignItems="center" justifyContent="center">
                        <Autocomplete
                            id="google-map-starting"
                            size="small"
                            sx={{ width: 200 }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            required
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No locations - enter to search"
                            onChange={(event, newValue) => {
                                setOptions(newValue ? [newValue, ...options] : options);
                                // console.log("VALUE HERE")
                                if (newValue != null) {
                                    // console.log(newValue.description)
                                    searchAddress(newValue.description)
                                    setValue(newValue);
                                }
                                // let res = getAddressGeocode(newValue.description)
                                // console.log(res)
                                // console.log(typeof res)
                                // setLatitudeStart(res.lat);
                                // setLongitudeStart(res.lon);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Starting Address" fullWidth />
                            )}
                            onKeyDown={event => {
                                if(event.key === 'Enter'){
                                    console.log("event", inputValue)
                                    searchAddress(inputValue)
                                }
                            }}
                            renderOption={(props, option) => {
                                const matches =
                                option.structured_formatting.main_text_matched_substrings || [];

                                const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                                );

                                return (
                                <li {...props}>
                                    <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </li>
                                );
                            }}
                        />
                        {addrFound != '' ? 
                        <Box>Pickup @ {addrFound}</Box>
                        :
                            null 
                        }
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            readOnly
                            defaultValue="Kalahari Resorts & Conventions - Poconos"
                            id="destination"
                            label="Destination Address"
                            InputProps={{
                                readOnly: true,
                              }}
                            name="destination"
                            autoFocus
                        />
                        
                        {/* <Autocomplete
                            id="google-map-ending"
                            size="small"
                            sx={{ width: 200 }}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={value}
                            noOptionsText="No locations"
                            onChange={(event, newValue) => {
                                setOptions2(newValue ? [newValue, ...options] : options);
                                console.log("VALUE END HERE")
                                console.log(newValue.description)
                                let res = getAddressGeocode(newValue.description)
                                console.log(res)
                                console.log(res.lat)
                                console.log(res.lon)
                                setLatitudeEnd("lat: " + res.lat);
                                setLongitudeEnd(res.lon);
                                setValue2(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue2(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Destination Address" fullWidth />
                            )}
                            renderOption={(props, option) => {
                                const matches =
                                option.structured_formatting.main_text_matched_substrings || [];

                                const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map((match) => [match.offset, match.offset + match.length]),
                                );

                                return (
                                <li {...props}>
                                    <Grid container alignItems="center">
                                    <Grid item sx={{ display: 'flex', width: 44 }}>
                                        <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                    </Grid>
                                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                        {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </li>
                                );
                            }}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                            <MobileDateTimePicker onChange={value => startTimeHandler(value)} label="Earliest Departure Time" size="small" value ={dayjs(new Date(startTime))} />
                            <MobileDateTimePicker onChange={value => endTimeHandler(value)} label="Latest Departure Time" size="small" value ={dayjs(new Date(endTime))}/> 
                        </LocalizationProvider>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={createEntryUX} // signUpHandler originally
                        >
                        Confirm
                        </Button>
                        {/* <Button
                            variant="contained"
                            fullWidth
                            sx={{ mt: 3, mb: 2 }}
                            onClick={switchViewHandler}
                            >Cancel
                        </Button> */}
                    </Stack>
            </div>
        </Stack>
        :
        <div>
            {/* <p>Press enter after typing address and/or radius to view map</p> */}
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <img style={{padding: "25px 0px 20px 0px"}} width="300" src="https://github.com/TechBash/Marketing/blob/master/2023/Logos/TechBash2023.png?raw=true"/>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box component="form" noValidate onSubmit={createEntryUX} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={emailHandler}
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="affliation"
                        label="Employer or University"
                        name="affiliation"
                        onChange={affiliationHandler}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                        required
                        fullWidth
                        id="details"
                        label="Phone Number"
                        name="details"
                        onChange={detailsHandler}
                        />
                    </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={signUpHandler}
                    >
                    Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link onClick={() => setSignedIn(false)} variant="body2" component="button">
                        Already have an account? Sign in
                        </Link>
                    </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
        </div>
        }
    </div>
    );
}
export default UserDetails;
