const baseUrl = "https://tyl427zgoe.execute-api.us-west-2.amazonaws.com/dev1/"
const API_KEY = "dBXTyoL0d22tJjXPabIYX7OaMmiluIvbRZGMgUVb"


export const createEntry = async(Radius, Details, EventId, Latitude, Longitude, Username, HasPassword, Password, IsFlying, StartWindow, EndWindow, FlightNumber, Email, LatitudeEndLocation, LongitudeEndLocation) => {
    // console.log(Radius, Details, EventId, Latitude, Longitude, Username, HasPassword, Password, IsFlying, StartWindow, EndWindow, FlightNumber, Email, LatitudeEndLocation, LongitudeEndLocation)
    let data = null
    await fetch(baseUrl + "entries",{
        method:"POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            },
        body : JSON.stringify({
            Radius : Radius,
            Details : Details,
            EventId : EventId,
            Latitude : Latitude, // starting point (pickup)
            Longitude : Longitude,
            LatitudeEndLocation : LatitudeEndLocation, //endpoint - should usually be prepopulaed fo ruser
            LongitudeEndLocation : LongitudeEndLocation,
            Username : Email,
            HasPassword : HasPassword,
            Password : Password,
            IsFlying : IsFlying,
            StartWindow : StartWindow,
            EndWindow : EndWindow,
            FlightNumber : FlightNumber,
            Email : Email
        })
    }).then(async res => {
        data = (await res.json())
    })
    return data
}

export const updateEntry = async(EntryId, PlotDate, Radius, Details, EventId, Latitude, Longitude, Username, HasPassword, Password, IsFlying, StartWindow, EndWindow, FlightNumber, Email, LatitudeEndLocation, LongitudeEndLocation) => {
    // console.log("UPDATING ENTRY")
    let data = null
    await fetch(baseUrl + "entries",{
        method:"PATCH",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            },
        body : JSON.stringify({
            EntryId: EntryId,
            PlotDate: PlotDate,
            Radius : Radius,
            Details : Details,
            EventId : EventId,
            Latitude : Latitude,
            Longitude : Longitude,
            LatitudeEndLocation : LatitudeEndLocation, //endpoint - should usually be prepopulaed fo ruser
            LongitudeEndLocation : LongitudeEndLocation,
            Username : Username,
            HasPassword : HasPassword,
            Password : Password,
            IsFlying : IsFlying,
            StartWindow : StartWindow,
            EndWindow : EndWindow,
            FlightNumber : FlightNumber,
            Email : Email
        })
    }).then(async res => {
        data = (await res.json())
        // console.log(data, "UPDATING ENTRY")
    })
    return data
}


export const getEntries = async(EventId) => {
    let data = null
    await fetch(baseUrl + "entries?EventId=" + EventId,{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
            }
    }).then(async res => {
        data = await res.json()
    })
    // console.log("Entries received from DB")
    return data
}

//export const getEntry = async(username)